<template>
    <PageLayout>
        <TabsNav :items="breadcrumbs_menu[0]['items']"/>
        <template>
            <div class="d-flex w-100 justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('orders') }}</h1>
                </div>
            </div>
            <div v-if="filter_show">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-form class="filter" @submit.prevent="getCountChart('search')">
                        <div class="filter__title mb-5">
                            {{ $t('filter_title') }}
                        </div>
                        <v-row class="py-5">
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFrom" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFrom" :error-messages="errors"
                                                          readonly v-on="on"
                                                          @click:clear="dateFrom = null"
                                                          :label="$t('date_from')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang" @change="dateMenuFrom = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateTo" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateTo" :error-messages="errors"
                                                          :label="$t('date_to')"
                                                          readonly v-on="on"
                                                          @click:clear="dateTo = null"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                   @change="dateMenuTo = false"></v-date-picker>
                                </v-menu>
                            </v-col>


                            <v-col cols="12" sm="6">
                                <ValidationProvider ref="business_process" rules="min:1" v-slot="{ errors, valid }">
                                    <SelectInput
                                        clearable
                                        multiple
                                        item-text="name" item-value="id"
                                        hide-details
                                        hide-label
                                        :items="business_processItems"
                                        v-model="business_process"
                                        name="business_process"
                                        id="business_process"
                                        :hideLabel="false"
                                        :label="$t('business_process')"
                                        @input="getDealStatuses();"
                                    ></SelectInput>
                                </ValidationProvider>
                            </v-col>


                            <v-col cols="12" sm="6">
                                <ValidationProvider ref="deal_status" rules="min:1" v-slot="{ errors, valid }">
                                    <SelectInput
                                        clearable
                                        multiple
                                        item-text="name" item-value="id"
                                        hide-details
                                        hide-label
                                        :items="deal_statusItems"
                                        v-model="deal_status"
                                        name="deal_status"
                                        id="deal_status"
                                        :hideLabel="false"
                                        :label="$t('deal_status')"
                                    ></SelectInput>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12">
                                <ValidationProvider ref="auditor" rules="min:1" v-slot="{ errors, valid }">

                                    <UserSelection
                                        multiple
                                        v-model="admins"
                                        id="auditor"
                                        :label="$t('manager')"
                                        :error="!valid"
                                        :valid="valid"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        clearable

                                    />
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details
                                          outlined
                                          solo
                                          flat
                                          dense
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn @click="searchFilter" :disabled="loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" class="infinity_button">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-form>
                </ValidationObserver>
            </div>


            <v-card flat class="mt-5 pb-2">
                <v-card-text>
                    <v-row>
                        <v-col class="pt-0" cols="12">
                            <div class="d-flex">
                                <div >
                                    <v-btn text @click="chart_type = 'day'" :color="chart_type === 'day' ? 'primary' : ''">
                                        {{$t('day')}}
                                    </v-btn>
                                    <v-btn text @click="chart_type = 'month'" :color="chart_type === 'month' ? 'primary' : ''">
                                        {{$t('month')}}
                                    </v-btn>
                                    <v-btn text @click="chart_type = 'year'" :color="chart_type === 'year' ? 'primary' : ''">
                                        {{$t('year')}}
                                    </v-btn>
                                </div>
                                <div class="ml-auto">
                                    <v-select
                                        v-model="m_header"
                                        :items="headers"
                                        chips
                                        label="Chips"
                                        multiple
                                        solo
                                        return-object
                                        item-text="text" item-value="value"
                                        hide-details
                                        flat
                                        dense
                                        outlined
                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"

                                        @change="updateHeaders"


                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                        clearable >
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                  (+{{ m_header.length - 1 }} {{$t('others')}})
                                                </span>
                                        </template>
                                    </v-select>
                                </div>

                            </div>
                        </v-col>
                        <v-col class="py-0" cols="12">
                            <v-card class="d-flex flex-wrap" flat tile >
                                <template v-for="(chart, i) in charts">
                                    <v-card  v-if="chart_type === chart.chart_type"
                                             min-width="100%"
                                             max-width="100%"
                                             class="pa-0 ma-0  mx-auto text-center" elevation="3" outlined tile>
                                        <horizontal-bar-chart v-if="false" :chartData="JSON.stringify(chart.chart)" ></horizontal-bar-chart>
                                        <bar-chart v-if="chart_type === 'day'" :chartData="JSON.stringify(chart.chart)" :options="optionsChartBar"></bar-chart>
                                        <line-chart v-else :chartData="JSON.stringify(chart.chart)" ></line-chart>

                                    </v-card>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card flat class="mt-5">
                <v-card-text class="pt-7">
                    <v-data-table :headers="headers2" :items="listAdminItems" :loading="loading" :locale="lang"
                                  :loading-text="$t('loading_please_wait')"
                                  disable-pagination hide-default-footer
                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                  class="elevation-0"
                                  :item-class="rowClass2">
                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                            <thead>
                            <tr class="rowHeadClass Table6">
                                <th v-for="h in headers" :key="h.value" class="table_header">
                                    <span>{{h.text}}</span>
                                </th>
                            </tr>
                            </thead>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <div :class="item.deleted ? 'red--text' : ''">
                                    <span class="font_weight_600" >
                                        {{  item.name }}
                                    </span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-card flat class="mt-5">
                <v-card-text class="pt-7">
                    <v-data-table :headers="filteredHeaders" :items="listtems" :options.sync="options" :page.sync="page"
                                  :items-per-page="perPage" :server-items-length="totalLists"
                                  :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                  @page-count="pageCount = options.pageCount = Number($event)"
                                  :loading-text="$t('loading_please_wait')"
                                  hide-default-footer

                                  :item-class="rowClass">


                        <template v-slot:item.number="{ item }">
                            <div :class="item.deleted ? 'red--text' : ''">
                                <v-hover v-slot="{ hover }">
                                    <div>
                                        <v-btn  text plain :to="'/order/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link" :style="'color:'+ item.order_step_color+'!important'">
                                            {{ item.number }}
                                        </v-btn>
                                    </div>
                                </v-hover>
                            </div>
                        </template>
                        <template v-slot:item.deal_status_id="{ item }">
                            <div :style="'color:'+ item.deal_step_color+'!important'">{{ item.deal_status }}</div>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                        </template>
                        <template v-slot:item.admin_id="{ item }">
                            <div>
                                <v-list subheader two-line class="background_none py-0">
                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                        <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                            <v-icon v-else color="primary" size="26">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 title_subtitle">
                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </template>
                        <template v-slot:item.active="{ item }">
                            <v-edit-dialog :return-value.sync="item.active" large persistent
                                           @save="activeDeal(item)" :save-text="$t('save')"
                                           :cancel-text="$t('cancel')">
                                <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                                <template v-slot:input>
                                    <v-select v-model="item.active" :items="itemYN" item-text="name"
                                              item-value="id"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                              color="primary"
                                              class="pt-5 elevation-0 rounded-lg input_text"
                                    ></v-select>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.task="{ item }">
                            <div>
                            <span v-for="task in item.tasks" :key="task.number" >
                                <v-btn text plain :to="showTask(task)" class="cursor-pointer font_weight_500 button_link" :title="task.title" :style="'color:'+ item.order_step_color+'!important'">
                                    {{ task.number }}
                                </v-btn>
                            </span>
                            </div>
                        </template>
                        <template v-slot:item.company_id="{ item }">
                            <div v-if="item.company">
                                <v-btn text plain :to="'/uved/' +  item.company.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                                    {{ item.company.company_name ? item.company.company_name : ( item.company.first_name + ' ' + item.company.last_name ) }}
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:item.business_process_id="{ item }">
                            <v-btn text plain :to="'/order/' + item.uuid + '/show'" class="button_link cursor-pointer font_weight_500" :style="'color:'+ item.order_step_color+'!important'">
                                {{item.business_process.name}}
                            </v-btn>
                        </template>
                        <template v-slot:item.text="{ item }">
                            <div>{{ item.text !== null  ? truncatedText(item.text) : '' }}</div>
                        </template>





                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pt-0 justify-end">
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table"
                                  :length="pageCount" :disabled="loading"></v-pagination>
                </v-card-actions>
            </v-card>

        </template>
        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5"  color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>
            <report-menu @filter_show="handleFilterShow"></report-menu>
        </template>


    </PageLayout>
</template>
<script>



import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import BarChart from "../../plugins/charts/BarChart";
import LineChart from "../../plugins/charts/LineChart";
import ReportMenu from "../../components/ReportMenu";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import HorizontalBarChart from "../../plugins/charts/HorizontalBarChart";
import SelectInput from "@/components/Form/SelectInput.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";


export default {
    name: "TaskReports",
    components: {
        ReportMenu,
        BarChart,
        LineChart,
        ValidationProvider,
        ValidationObserver,
        UserSelection,
        HorizontalBarChart,
        PageLayout,
        SelectInput,
        TabsNav

    },
    data() {
        return {
            loading:false,
            charts: [],
            optionsChartLine: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },
            optionsChartBar: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            //stepSize: 1,
                            beginAtZero: true,
                            callback: function (value) {
                                if (value % 1 === 0) {
                                    return value;
                                }
                            }
                        }
                    }]
                },
            },

            filter_show: false,
            email_search: null,
            chart_type: 'month',
            listtems: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalLists: 0,
            options: {},
            headers: [
                {
                    text: this.$t('columns.task.number'),
                    align: "left",
                    sortable: true,
                    value: "number",
                    hide: false
                },
                {
                    text: this.$t('columns.deal.business_process_id'),
                    align: "left",
                    sortable: true,
                    value: "business_process_id",
                    hide: false
                },
                {
                    text: this.$t('columns.deal.deal_status_id'),
                    align: "left",
                    sortable: true,
                    value: "deal_status_id",
                    hide: false
                },
                {
                    text: this.$t('columns.deal.task'),
                    align: "left",
                    sortable: true,
                    value: "task",
                    hide: true
                },
                {
                    text: this.$t('columns.task.admin'),
                    align: "left",
                    sortable: true,
                    value: "admin_id",
                    hide: false
                },

                {
                    text: this.$t('columns.task.uved'),
                    align: "left",
                    sortable: true,
                    value: "company_id",
                    hide: false
                },
                {
                    text: this.$t('columns.deal.created_at'),
                    align: "left",
                    sortable: true,
                    value: "created_at",
                    hide: false
                },



            ],
            m_header: [],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            listAdminItems: [],
            headers2:[
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t('quantity_short'),
                    align: "left",
                    sortable: true,
                    value: "count",
                },
                {
                    text: this.$t('deal_amount'),
                    align: "left",
                    sortable: true,
                    value: "total_cost",
                },
                {
                    text: this.$t('daily_plan'),
                    align: "left",
                    sortable: true,
                    value: "daily",
                },
                {
                    text: this.$t('weekly_plan'),
                    align: "left",
                    sortable: true,
                    value: "weekly",
                },
                {
                    text: this.$t('monthly_plan'),
                    align: "left",
                    sortable: true,
                    value: "monthly",
                },
            ],
            admins: null,
            business_process: null,
            business_processItems: [],
            order_status: null,
            order_statusItems: [],
            deal_status: null,
            deal_statusItems: [],
            breadcrumbs_menu:[
                {
                    items: [
                        {
                            title: 'menu_dashboard',
                            route: {name: "dashboard"},
                            permissions: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer'],
                            notification_group: 'dashboard',
                            icon: "icon-chart",
                        },
                        {
                            title: 'statistics',
                            route: {name: "report_order"},
                            permissions: ['administrator', 'director'],
                            notification_group: 'report_order',
                            icon: 'icon-analytics-graph',
                        }
                    ],
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    watch: {
        options: {
            handler() {
                this.getCountList()
            },
            deep: false
        }
    },
    async mounted() {
        this.dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.dateTo = this.$moment().format('YYYY-MM-DD');
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getCountChart()
        await this.getCountListAdmin()
        for (let i = 0; i < this.headers.length; i++) {
            if(this.headers[i].hide === false){
                this.m_header.push(this.headers[i]);
            }
        }
        await this.getBusinessProcess();


    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClassAuto' : 'rowMobiClass';
        },
        rowClass2(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table6' : 'rowMobiClass';
        },
        updateHeaders(){
            for (let i = 0; i < this.headers.length; i++) {
                let yes = 0;
                for (let x = 0; x < this.m_header.length; x++) {
                    if(this.headers[i].value === this.m_header[x].value){
                        yes = 1;
                    }
                }
                this.headers[i].hide = yes !== 1;
            }
        },
        searchFilter(){
            this.getCountChart();
            this.getCountListAdmin();
            this.getCountList();
        },
        showTask(item) {
            return {
                name: 'task.show',
                params: {
                    id: item.uuid
                }
            }
        },
        async getBusinessProcess() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/admin_business_process`, {
                    params: params,
                })
                .then(res => {
                    this.business_processItems = res.body.data
                    //  this.business_processItems.push({'id':0,'name':this.$t('all_orders')})
                })
                .catch(err => {
                    this.business_processItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_business_processes'))
                });
        },
        async getDealStatuses() {
            this.deal_statusItems = []
            if(this.business_process){
                this.loading = true
                let params = {}
                params.business_processes = this.business_process
                await this.$http
                    .get("admin/deal_status", {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.deal_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }

        },

        getCountChart() {
            var formData = new FormData()
            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }


            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }
            if (this.business_process && this.business_process.length > 0) {
                formData.append(`business_process`, this.business_process)
            }

            if (this.deal_status && this.deal_status.length > 0) {
                formData.append(`deal_status`, this.deal_status)
            }


            this.$http
                .post('admin/reports/order_chart',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.charts = res.body.data
                })
                .catch(err => {
                    this.charts = [];
                    this.$toastr.error(this.$t('failed_to_get_list_charts'))
                })
        },
        async getCountList() {
            this.loading = true
            var formData = new FormData()
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                formData.append(`sortBy`, sortBy[0])
            }
            if (sortDesc[0] !== undefined) {
                formData.append(`sortDir`, sortDesc[0] ? 'asc' : 'desc')
            }
            if (page !== undefined) {
                formData.append(`page`, page)
            }
            if (itemsPerPage !== undefined) {
                formData.append(`perPage`, itemsPerPage)
            }

            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }

            if (this.business_process && this.business_process.length > 0) {
                formData.append(`business_process`, this.business_process)
            }
            if (this.deal_status && this.deal_status.length > 0) {
                formData.append(`deal_status`, this.deal_status)
            }

            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }


            await this.$http
                .post('admin/reports/order',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.listtems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalLists = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.listtems = []
                    this.totalLists = 0
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getCountListAdmin() {
            this.loading = true


            var formData = new FormData()

            if (this.dateFrom) {
                formData.append(`dateFrom`, this.dateFrom)
            }
            if (this.dateTo) {
                formData.append(`dateTo`, this.dateTo)
            }
            if (this.business_process && this.business_process.length > 0) {
                formData.append(`business_process`, this.business_process)
            }
            if (this.deal_status && this.deal_status.length > 0) {
                formData.append(`deal_status`, this.deal_status)
            }
            if (this.admins && this.admins.length > 0) {
                for (let i in this.admins) {
                    if (this.admins[i].id !== undefined && this.admins[i].id > 0) {
                        formData.append(`admins[${i}]`, this.admins[i].id)
                    }
                }
            }
            await this.$http
                .post('admin/reports/order_admin',  formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.listAdminItems = res.body.data
                })
                .catch(err => {
                    this.listAdminItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_administrators'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>

<style scoped lang="scss">
.filter {
    background-color: #fff;
    padding: $grid-gutter;
    border-radius: $border-radius-root;
}

.filter__title {
    font-size: 20px;
}
</style>

