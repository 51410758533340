import { Bar } from 'vue-chartjs'
import Chart from 'chart.js'

export default {
    extends: Bar,
    props: {
        chartData: {
            type: String,
            default: null
        },
        options: {
            type: Object,
            default() {
                return {
                    responsive: true,
                    maintainAspectRatio: false,

                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            font: {
                                family: "'Stem', sans-serif",  // Указываем шрифт
                                size: 22  // Размер шрифта
                            },
                            color: 'red',  // Цвет текста легенды
                            padding:0

                        }
                    },

                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom',
                            align: 'center',
                            labels: {
                                font: {
                                    family: "'Stem', sans-serif",  // Указываем шрифт
                                    size: 22  // Размер шрифта
                                },
                                color: 'red',  // Цвет текста легенды
                                padding:120

                            }
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                };
            }
        }
    },
    watch: {
        chartData(val) {
            const parsedData = JSON.parse(val);
            this.renderChart(parsedData, this.options)
        },
    },
    mounted() {
        const parsedData = JSON.parse(this.chartData);
        this.renderChart(parsedData, this.options);
    },
    methods: {
        renderChart(data, options) {
            console.log(data)
            if (this.$data._chart) {
                this.$data._chart.destroy();  // Удаляем старый график
            }
            this.$data._chart = new Chart(this.$refs.canvas.getContext('2d'), {
                type: 'horizontalBar',  // Используем тип 'horizontalBar' для горизонтального графика
                data: data,
                options: options
            });
        }
    }
}
